/* eslint-disable no-useless-concat */
import { MARKETS, LANGUAGE_CODES } from "../constants/config-constants";
import MuleFetchUtil from "../common/mule-fetch-util";
export const getCountryCode = () => {
  // return  env.COUNTRY_CODE;
  return "VN";
};

export const getLanguageCode = () => {
  const countryCode = getCountryCode();
  let languageCode;
  switch (countryCode) {
    case MARKETS.VIETNAM: {
      languageCode = LANGUAGE_CODES.VN;
      break;
    }
    default: {
      languageCode = LANGUAGE_CODES.EN;
    }
  }

  return languageCode;
};

export const setCookie = (name, value) => {
  const cookieValue = value + "; path=/";
  document.cookie = name + "=" + cookieValue;
};
export const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  let result = null;
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    const cookieName = cookie[0];
    const cookieValue = cookie[1];
    // console.log(cookieName,cookieValue)
    if (cookieName === name) {
      result = cookieValue;
      break;
    }
  }
  return result;
};
export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
export const LogoutInvalidToken = () => {
  document.cookie =
    "idToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "refreshToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  alert("Something went wrong. please login again");
  window.location.assign("/login");
};

export const RefreshTokenReload = async (refreshToken) => {
  let obj = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };
  const countryCode = localStorage.getItem("countryCode");
  const idToken = getCookie("idToken");
  let result = MuleFetchUtil.post(
    "fb/accounts/refreshtoken",
    { customerToken: idToken, countryCode: countryCode },
    obj,
    "signInWithPassword"
  );
  result.then((response) => {
    if (response.statusCode === 200 && response["response-data"].id_token) {
      setCookie("idToken", response["response-data"].id_token);
      window.location.reload();
    } else {
      // LogoutInvalidToken();
      document.cookie =
        "idToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "refreshToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.assign("/login");
    }
  });
};

export const sortCountryArray = (countries) => {
  const dummy = ["id","idic","vn", "ph", "th","pk"];
  const sortedCountryArray = countries.sort((a, b) => {
    return dummy.indexOf(a) - dummy.indexOf(b);
  });
  return sortedCountryArray;
};
