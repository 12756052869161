import React, { useState, useEffect } from "react";
import Main from "./components/Main";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import config from "react-global-configuration";
import ConfigHelper from "./helper/config-helper";
import { StoreContextProvider } from "./components/ContextStore/StoreContextProvider";
import { Provider as ReduxProvider } from "react-redux";
import store from "./components/ContextStore/Store/Store";
function App() {
  const [status, setStatus] = useState(false);
  const marketSpecificConfig = () => {
    ConfigHelper.loadConfig().then((data) => {
      config.set({ configsGlobal: data }, { freeze: false, assign: false });
      if (config.get("configsGlobal")) {
        setStatus(true);
      }
    });
  };

  useEffect(() => {
      if (window.top !== window.self) {
      window.top.location = window.location;
    }
    
    marketSpecificConfig();
  }, []);
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <StoreContextProvider>
          <React.StrictMode>{status === true && <Main />}</React.StrictMode>
        </StoreContextProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;
