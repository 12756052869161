import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import UserAccess from './components/UserAccessManagement/UserAccess/UserAccess';
// const LoginPage = lazy(() => import('./components/login/login'));
const HomePage = lazy(() => import("./components/HomePage/HomePage"));
const LandingPage = lazy(() =>
  import("./components/LandingHomePage/LandingPage/LandingPage")
);
const LoginPage = lazy(() => import("./components/login/LoginMainPage/Login"));
const FirstLogin = lazy(() =>
  import("./components/login/Firstlogin/Firsttime")
);
const ForgotPasswordPage = lazy(() =>
  import("./components/ForgotPassword/forgotPassword")
);
const TeamLevelsView = lazy(() =>
  import("./components/TeamLevelsView/TeamLevelsView")
);
const UserAccess = lazy(() =>
  import("./components/UserAccessManagement/UserAccess/UserAccess")
);
const OperationalReportLandingPage = lazy(() =>
  import("./components/OperationalReport/OperationalReports")
);
const ReportPage = lazy(() =>
  import("./components/OperationalReport/ReportPage/ReportPage")
);
const DistributorTeamLevelsView = lazy(() =>
  import("./components/DistributorTeamLevelsView/DistributorTeamLevelsView")
);
const DisciplinaryTeamLevelsView = lazy(() => 
  import("./components/DisciplinaryTeamLevelsView/DisciplinaryTeamLevelsView")
);
const TaskManagment = lazy(() => import("./components/TaskManagment/index"));
const DeviceGeoCompliance = lazy(() =>
  import("./components/DeviceGeoCompliance/DeviceGeoCompliance")
);
const EstimatedBestPrice = lazy(() =>
  import("./components/EstimatedBestPrice/EstimatedBestPrice")
);
const AdminConfiguration = lazy(() =>
  import("./components/LandingHomePage/AdminConfiguration/AdmiinConfiguration")
);

const Routes = (props) => {
  const { ucubeAccess, operationalAccess } = props;
  const adminAccess =
    localStorage.getItem("adminAccess") === "true" ? true : false;
  return (
    <Suspense fallback={<p>loading...</p>}>
      <Switch>
        <Route exact path={"/login"} component={LoginPage} />
        <Route exact path={"/signup"} component={FirstLogin} />
        <Route exact path={"/homepage"} component={HomePage} />
        <Route exact path={"/"} component={LandingPage} />
        <Route exact path={"/landingscreen"} component={LandingPage} />
        <Route exact path={"/forgotpassword"} component={ForgotPasswordPage} />
        {adminAccess && (
          <Route exact path={"/taskmanagment"} component={TaskManagment} />
        )}
        {adminAccess && (
          <Route
            exact
            path={"/devicegeocompliance"}
            component={DeviceGeoCompliance}
          />
        )}
        {adminAccess && (
          <Route
            exact
            path={"/adminconfigurationpage"}
            component={AdminConfiguration}
          />
        )}
        {ucubeAccess && (
          <Route exact path={"/teamlevels"} component={TeamLevelsView} />
        )}
        {ucubeAccess && (
          <Route
            exact
            path={"/distributorteamlevels"}
            component={DistributorTeamLevelsView}
          />
        )}
        {ucubeAccess && (
          <Route
            exact
            path={"/disciplinaryteamlevels"}
            component={DisciplinaryTeamLevelsView}
          />
        )}
        {adminAccess && (
          <Route exact path={"/useraccess"} component={UserAccess} />
        )}
        {adminAccess && (
          <Route
            exact
            path={"/estimatedbestprice"}
            component={EstimatedBestPrice}
          />
        )}
        {operationalAccess && (
          <Route exact path={"/reportpage"} component={ReportPage} />
        )}
        {operationalAccess && (
          <Route
            exact
            path={"/operationalreportslandingpage"}
            component={OperationalReportLandingPage}
          />
        )}
        {/* <Route exact path={'/outlets'} component={SchedulerPage} /> */}
        <Route path={"/*"} render={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
